* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Inter";
  src: url("../public/assets/fonts/Inter-Regular.ttf");
}

body {
  margin: 0;
  background: #000;
  font-family: Inter, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #363607;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: #fbda00;
  cursor: pointer !important;
  border-radius: 2px;
}
.notification-container {
  width: fit-content !important;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  @media (max-width: 430px) {
    font-size: 14px;
    font-weight: 400;
  }
}

.notification {
  padding: 15px 30px 15px 58px !important;
  color: black !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}
.notification-warning {
  background-color: #fbda00 !important;
}

body {
  background-color: black;
  overflow-x: hidden;
}

.container {
  max-width: 1360px;
  margin: 0 auto;
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
  }
}
